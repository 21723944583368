@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

#home-page {
  margin: 0;
  margin-top: 78px;

  width: 100%;
  overflow: scroll;
}

button {
  position: relative;
}

#landing {
  position: relative;
  overflow: hidden;
  background-color: #4548a3;
  height: calc(100vh + 30px);
  width: 100%;
  top: -90px;
}

#landing .flexbox {
  width: 100%;
}

#landing h1 {
  position: relative;
  font-family: "Roboto Flex", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: white;
  font-weight: 500;
  font-size: 6vw;
  width: 50%;
  padding-top: 5%;
  margin-left: 6%;
  z-index: 10;
}

#landing .btn-container {
  margin-top: 30px;
  margin-left: 92px;
}

#landing img {
  position: fixed;
  /* max-width: 705px; */
  width: 50%;
  right: 0px;
  z-index: 0;
  object-fit: cover;
  animation: fade-in 3s;
}

#landing #login {
  padding: 10px 40px 10px 40px;
  margin-left: 40px;
}

#landing #signup {
  padding: 10px 80px 10px 80px;
  background-color: white;
}

/* Mobile settings for front landing section */
@media (max-width: 950px) {
  #landing-interface {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #landing h1 {
    margin: 0;
    width: 80%;
    font-size: 80px;
    /* align-self: center; */
  }

  #landing .btn-container {
    margin-left: 0px;
  }

  #landing img {
    max-width: 100%;
    height: 100vh;
    filter: blur(4px);
  }
}

#setup-steps {
  position: relative;
  top: -30px;
  background-color: #363d43;
  width: 100%;
  height: 1200px;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0px 5px 15px #363d43;
}

#setup-steps h2 {
  color: white;
  position: relative;
  font-size: 48px;
  font-weight: 400;
  left: 180px;
  top: 40px;
}

#setup-steps #enumeration {
  background-color: #363d43;
  position: absolute;
  top: 49px;
  left: 101px;
  z-index: 0;
}

#setup-steps .art {
  position: absolute;
  max-width: 60%;
  right: -120px;
  top: 40px;
  opacity: 0%;
  transition: 1.6s;
}
#setup-steps .art.visible {
  opacity: 30%;
}

#setup-steps .l1 {
  color: white;
  position: relative;
  top: 143px;
  left: 200px;
  font-size: 20px;
  overflow: visible;
}
#setup-steps .l1 * {
  margin-right: 30px;
  position: relative;
}

#setup-steps .l1 #login {
  position: relative;
  right: 12px;
  text-decoration: underline;
  padding: 10px 20px 10px 20px;
  transition: 0.3s;
  border-radius: 7px;
}
#setup-steps .l1 #login:hover {
  background-color: #ffffff30;
  cursor: pointer;
}

#setup-steps a {
  color: inherit;
}

#setup-steps .l2 {
  color: #ffffffb2;
  font-size: 20px;
  left: 200px;
  position: relative;
  left: 200px;
  top: 260px;
}
#setup-steps .l3 {
  color: #ffffffb2;
  font-size: 20px;
  left: 200px;
  position: relative;
  left: 200px;
  top: 370px;
}

#setup-steps .card-container {
  display: flex;
  justify-content: center;
  gap: 100px;
  position: relative;
  top: 430px;
}

#setup-steps .card {
  position: relative;
  display: inline-block;
  color: white;
  background-color: #ffffff10;
  overflow: hidden;
  width: 498px;
  height: 427px;
  border-radius: 10px;
  padding: 0px;
  border: solid;
  border-width: 1px;
  border-color: white;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  transition: transform 0.3s;
  box-shadow: 0px 5px 15px #22272a;
  box-sizing: content-box;
}

#setup-steps .card:hover {
  transform: scale(1.01);
}

#setup-steps .card.right:hover {
  cursor: default;
}
#setup-steps .card.right:hover .icon-btn {
  cursor: pointer;
}

#setup-steps .card.left:hover {
  cursor: pointer;
}

#setup-steps .card a {
  display: inline-block;
  opacity: 50%;
  font-size: 20px;
  padding: 0px 20px 0px 20px;
  text-decoration: none;
}

#setup-steps .card h3 {
  font-size: 36px;
  font-weight: 400;
  margin-top: 30px;
  margin-left: 20px;
}

#setup-steps .card .art {
  position: absolute;
  top: auto;
  opacity: 100%;
  max-width: 100%;
  right: 0px;
  bottom: 0px;
  border-radius: 0px;
}

/* Mobile resize for setup steps section */

@media (max-width: 1251px) {
  #setup-steps {
    height: 1800px;
  }
  #setup-steps .card-container {
    flex-direction: column;
  }
}

@media (max-width: 950px) {
  #setup-steps #landing-steps-art {
    display: none;
  }
}

@media (max-width: 690px) {
  #setup-steps #enumeration {
    left: 30px;
  }
  #setup-steps .l1 {
    left: 129px;
  }
  #setup-steps .l2 {
    left: 129px;
  }
  #setup-steps .l3 {
    left: 129px;
  }
  #setup-steps h2 {
    left: 109px;
  }
}

.icon-btn {
  position: relative;
  display: inline-block;
  justify-content: center;
  top: 11px;
  margin-top: 32px;
}

#demo-section {
  background-color: #3d646a;
  padding-top: 50px;
  padding-bottom: 200px;
}

#demo-section h1 {
  color: white;
  font-weight: 400;
  font-size: 64px;
  text-align: center;
  margin-bottom: 10px;
}

#demo-section p {
  text-align: center;
  color: white;
  font-size: 18px;
  margin-bottom: 80px;
}

#demo-section .yt-video {
  aspect-ratio: 16/9;
  width: 80%;
  border: none;
  margin-left: 10%;
  box-shadow: 0px 5px 15px #363d43;
}
