.blog {
  width: 100%;
  /* height: 100%; */
  /* background-color: red; */
  margin-top: 65px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}

.blog .blog-header {
  width: 100%;
  height: 500px;
  backdrop-filter: brightness(60%);
  backdrop-filter: blur(2px);
  background-size: cover;
  color: white;
  font-weight: 400;
}

.blog .blog-header h1 {
  font-size: 96px;
  position: relative;
  top: 130px;
  left: 60px;
}
.blog .blog-header h3 {
  font-size: 24px;
  position: relative;
  top: 140px;
  left: 70px;
}

.blog .blog-content {
  margin-top: 100px;
  width: 70%;
  min-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 200px;
  color: #363d43;
  display: flex;
  flex-direction: column;
}

.blog .blog-content p {
  font-size: 18px;
  line-height: 2;
}
.blog .blog-content a {
  color: inherit;
  background-color: #95d3740f;
  text-decoration: none;
  font-weight: bold;
}

.blog .blog-content img {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  justify-self: center;
  display: block;
}

.blog .blog-content code {
  color: rgb(179, 54, 146);
}

.blog .blog-content .note {
  background-color: rgba(229, 244, 255, 0.534);
  padding: 10px 30px 10px 30px;
  border-radius: 7px;
  font-style: italic;
}
