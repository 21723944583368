.icon-btn {
  position: relative;
  display: flex;
  align-items: baseline;
  border: solid;
  border-width: 1px;
  border-color: white;
  border-radius: 5px;
  height: 46px;
  /* padding-left: 25px;
    padding-right: 25px; */
  text-align: center;
  transition: transform 0.3s;
}

.icon-btn:hover {
  transform: scale(1.02);
}

.icon-btn .icon-text {
  position: relative;
  display: inline;
  font-size: 15px !important;
  opacity: 100% !important;
  color: white;
  text-decoration: none;
  margin-left: 20px;
  padding: 0px 10px 0px 10px;
}

.icon-btn .icon {
  position: relative;
  height: 29px;
  top: 7px;
  left: 18px;
  object-fit: cover;
}
