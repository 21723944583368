.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo img {
  height: 50px;
}

.footer-links {
  display: flex;
  gap: 15px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #ddd;
}

.footer-links img {
  height: 24px;
  vertical-align: middle;
}
