/* Global CSS */

@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 562px;
  font-family: "Roboto Flex", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  z-index: 10;
}

.logo-font {
  font-family: "Roboto Flex", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 100;
}

.btn-fill {
  font-family: "Roboto Flex", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  padding: 10px 30px 10px 30px;
  border-radius: 7px;
  border: none;
  color: #363d43;
  transition: 0.3s;
  font-weight: 500;
}
.btn-fill.dark {
  color: white;
  background-color: #363d43;
}

.btn-fill:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.btn-trace {
  font-family: "Roboto Flex", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  padding: 10px 30px 10px 30px;
  border-radius: 7px;
  border: solid;
  background-color: transparent;
  border-width: 1px;
  border-color: white;
  color: white;
  transition: 0.3s;
  font-weight: 500;
}
.btn-trace.dark {
  border-color: #363d43;
  color: #363d43;
}

.btn-trace:hover {
  transform: scale(1.02);
  cursor: pointer;
  background-color: #ffffff20;
}

.btn-glow {
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.712);
  transition: 0.3s;
}

.btn-glow:hover {
  box-shadow: 0px 0px 23px white;
}

.flex-center-vert {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.align-center-vert {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.fade-with-vis {
  transition: 0.8s;
  transform: translateY(30px);
  opacity: 0%;
}
.fade-with-vis.visible {
  transform: translateY(0px);
  opacity: 100%;
}

.flexbox {
  display: flex;
}

.center-contents-horiz {
  display: flex;
  justify-content: center;
}

.text-input {
  padding: 10px;
  border-radius: 7px;
  border: 1px solid #363d43;
}

iframe {
  aspect-ratio: 16/9;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.popup-content {
  border-radius: 10px;
  padding: 10px !important;
  background-color: white;
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  max-width: 570px;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
